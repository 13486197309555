























































































.timepicker-container {
  display: flex;
      align-items: center;
  span {
    padding: 0px 10px;
  }
}
